import React from 'react'

import styled from 'styled-components'

import media from 'styles/media'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

type AboutAurecProps = {
  image: {
    src: IGatsbyImageData
    alt: string
  }
  title: string
  description: string
  reverse?: boolean
}

const StyledWrapper = styled.div<{ reverse?: boolean }>`
  padding: 0 20px;
  max-width: 1400px;

  display: flex;
  flex-direction: column-reverse;

  ${media.lg.min} {
    padding: 0 50px;
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    align-items: center;
    margin: 0 auto;
    gap: 50px;
  }

  img {
    ${media.md.min} {
      margin: ${({ reverse }) => (reverse ? '0 auto 0 0' : '0 0 0 auto')};
      width: 80%;
    }
  }
`

const StyledImageWrapper = styled.div``

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;

  ${media.lg.min} {
    max-width: 600px;
  }
`

const StyledHeading = styled.h1`
  font-size: 32px;
  margin-bottom: 0;
  line-height: 41px;

  ${media.lg.min} {
    br {
      display: none;
    }
  }

  ${media.xl.min} {
    font-size: 42px;
  }
`

const StyledDescription = styled.p`
  margin-top: 60px;
  line-height: 2.2;
`

const AboutAurec: React.FC<AboutAurecProps> = ({
  image,
  title,
  description,
  reverse,
}) => {
  return (
    <StyledWrapper reverse={reverse}>
      <StyledContentWrapper>
        <StyledHeading dangerouslySetInnerHTML={{ __html: title }} />
        <StyledDescription>{description}</StyledDescription>
      </StyledContentWrapper>
      <StyledImageWrapper>
        <GatsbyImage image={image.src} alt={image.alt} />
      </StyledImageWrapper>
    </StyledWrapper>
  )
}

export default AboutAurec
