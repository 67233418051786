import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import media from 'styles/media'

import aurecHomeLogo from 'assets/icons/aurec-home-logo.svg'
import arrowSimpleLeft from 'assets/icons/arrow-simple-left.svg'
import closeIcon from 'assets/icons/close.svg'

const StyledWrapper = styled.div`
  position: fixed;
  width: 300px;
  height: 140px;
  background-color: ${({ theme }) => theme.colors.white};
  right: 0;
  bottom: 60px;
  z-index: 3;
  box-shadow: 0 20px 40px #00000029;
  border-radius: 45px 0 0 45px;
  display: flex;
  padding: 35px;
  line-height: 1.5;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightorange};
  }

  ${media.lg.min} {
    bottom: 15%;
  }
`

const StyledExtendedWrapper = styled.div`
  position: fixed;
  height: 300px;
  background-color: ${({ theme }) => theme.colors.white};
  right: 0;
  left: 20px;
  bottom: 60px;
  z-index: 3;
  box-shadow: 0 20px 40px #00000029;
  border-radius: 45px 0 0 45px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  line-height: 1.5;
  gap: 16px;
  align-items: center;

  p {
    text-align: center;
    ${media.lg.min} {
      text-align: left;
    }
  }

  ${media.lg.min} {
    bottom: 15%;
    flex-direction: row;
    height: 140px;
    left: auto;
    width: 70%;
    justify-content: space-evenly;

    img {
      width: 115px;
      height: 93px;
    }
  }
`

const StyledButton = styled.button`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.colors.green};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 34px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  border: ${({ theme }) => `2px solid ${theme.colors.green}`};
  transition: 0.3s;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }

  p {
    margin-bottom: 0;
  }

  img {
    margin-bottom: 0;
    position: absolute;
    right: 27px;
  }

  ${media.lg.min} {
    max-width: 330px;
  }
`

const StyledCloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
`

const AskAboutFlats = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <StyledWrapper onClick={() => setIsOpen(!isOpen)}>
        <img src={aurecHomeLogo} alt="logo aurec home" />
        <p>Zapytaj o mieszkania</p>
        <img src={arrowSimpleLeft} alt="strzałka w lewo" />
      </StyledWrapper>
      {isOpen && (
        <StyledExtendedWrapper>
          <img src={aurecHomeLogo} alt="logo aurec home" />
          <p>
            Nie znalazłeś jeszcze swojej wymarzonej nieruchomości? <br />
            Określ swoje oczekiwania i otrzymuj dopasowane oferty.
          </p>
          <StyledButton
            as="a"
            href="https://miasteczkojutrzenki.pl/"
            target="_blank"
          >
            Zapytaj o mieszkania
          </StyledButton>
          <StyledCloseButton onClick={() => setIsOpen(false)}>
            <img src={closeIcon} alt="zamknij" />
          </StyledCloseButton>
        </StyledExtendedWrapper>
      )}
    </>
  )
}

export default AskAboutFlats
