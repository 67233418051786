import React from 'react'
import styled from 'styled-components'

import media from 'styles/media'

const StyledWrapper = styled.div`
  margin-top: 60px;
  padding: 0 20px;
`
const StyledTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 0;
  line-height: 55px;

  ${media.lg.min} {
    margin-top: 120px;
    text-align: center;

    br {
      display: none;
    }
  }
`
const StyledDescription = styled.p`
  margin: 30px auto 0 auto;
  line-height: 2.5;
  max-width: 1000px;

  ${media.lg.min} {
    text-align: center;
  }
`

type ShortAboutProps = {
  title: string
  description: string
}

const ShortAbout: React.FC<ShortAboutProps> = ({ title, description }) => {
  return (
    <StyledWrapper>
      <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
      <StyledDescription>{description}</StyledDescription>
    </StyledWrapper>
  )
}

export default ShortAbout
