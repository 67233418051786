import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'

import Header from 'components/sections/main-header'

import Newsletter from 'components/sections/newsletter'

import AboutAurec from 'components/sections/about-aurec'

import { JakMozecieKorzystacPageQuery } from 'types/graphql'
import WhatYouCanDo from 'components/sections/what-you-can-do'
import ShortAbout from 'components/sections/short-about'
import Neighbourhood from 'components/sections/neighbourhood'

import Map from 'components/map'
import Seo from 'components/layout/seo'
import AskAboutFlats from 'components/ask-about-flats'

const JakKorzystac: React.FC<PageProps<JakMozecieKorzystacPageQuery>> = ({
  data,
}) => {
  const PAGE = data?.wpPage?.jakMozecieKorzystac

  const HERO_IMAGE = {
    src: PAGE?.benefitHeroImg?.localFile?.childImageSharp?.gatsbyImageData!,
    alt: PAGE?.benefitHeroImg?.altText!,
  }

  const ATTRACTIONS = PAGE?.benefitAttraction?.map((attraction) => ({
    title: attraction?.benefitAttractionTitleSingle,
    image: {
      src: attraction?.benefitAttractionImgSingle?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: attraction?.benefitAttractionImgSingle?.altText!,
    },
    description: attraction?.benefitAttractionDescriptionSingle,
  }))

  return (
    <Layout>
      <Seo
        title={data?.wpPage?.seo?.title!}
        description={data?.wpPage?.seo?.metaDesc!}
        ogTitle={data?.wpPage?.seo?.opengraphTitle!}
        ogDescription={data?.wpPage?.seo?.opengraphDescription!}
        ogImage={data?.wpPage?.seo?.opengraphImage?.sourceUrl!}
      />
      <AskAboutFlats />
      <Header
        image={HERO_IMAGE}
        title={PAGE?.benefitHeroTitle!}
        buttonText="Dowiedz się więcej"
        buttonColor="green"
      />

      <AboutAurec
        image={{
          src: PAGE?.benefitDescriptionImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.benefitDescriptionImg?.altText!,
        }}
        title={PAGE?.benefitDescriptionTitle!}
        description={PAGE?.benefitDescriptionText!}
        reverse
      />

      <WhatYouCanDo attractions={ATTRACTIONS} />

      <ShortAbout
        title={PAGE?.benefitAurecAvtiveTitle!}
        description={PAGE?.benefitAurecAvtiveDescription!}
      />

      <Map
        contactPins={PAGE?.benefitMapInvestments!}
        attractionsPins={PAGE?.benefitMapAttraction}
      />

      <Neighbourhood
        title={PAGE?.benefitDistrictTitle!}
        description={PAGE?.benefitDistrictDescription!}
        image={{
          src: PAGE?.benefitDistrictImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.benefitDistrictImg?.altText!,
        }}
        sign={PAGE?.benefitDistrictSign!}
        title2={PAGE?.benefitDistrictTitle2!}
        description2={PAGE?.benefitDistrictDescription2!}
        image2={{
          src: PAGE?.benefitDistrictImg2?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.benefitDistrictImg2?.altText!,
        }}
        sign2={PAGE?.benefitDistrictSign2!}
      />

      <Newsletter withImage runningManImage />
    </Layout>
  )
}

export default JakKorzystac

export const query = graphql`
  query JakMozecieKorzystacPage {
    wpPage(slug: { regex: "/jak-mozecie-skorzystac/" }) {
      id
      jakMozecieKorzystac {
        benefitDescriptionTitle
        benefitDistrictDescription
        benefitDistrictDescription2
        benefitDistrictTitle
        benefitDistrictTitle2
        benefitHeroTitle
        benefitDescriptionText
        benefitHeroImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        benefitDescriptionImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        benefitAurecAvtiveDescription
        benefitAurecAvtiveTitle
        benefitDistrictImg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        benefitDistrictImg2 {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
        benefitAttraction {
          benefitAttractionDescriptionSingle
          benefitAttractionTitleSingle
          benefitAttractionImgSingle {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
              }
            }
            altText
          }
        }
        benefitDistrictSign
        benefitDistrictSign2
        benefitMapInvestments {
          benefitMapInvestmentsSingle
          benefitMapMapLatSingle
          benefitMapMapLngSingle
        }
        benefitMapAttraction {
          benefitMapAttractionMapLat
          benefitMapAttractionMapLng
          benefitMapAttractionName
          fieldGroupName
        }
      }
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`
