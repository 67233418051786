import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import media from 'styles/media'

const StyledWrapper = styled.div`
  margin-top: 55px;
  padding: 0 20px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;

  ${media.lg.min} {
    padding: 0 50px;
    margin-top: 80px;
  }
`

const StyledText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.orange};

  ${media.lg.min} {
    display: none;
  }
`

const StyledTitle = styled.p`
  margin-top: 22px;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.5;

  ${media.lg.min} {
    text-align: left;

    br {
      display: none;
    }
  }
`

const StyledSliderWrapper = styled.div`
  margin-top: 60px;
  position: relative;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > img {
    object-fit: cover;
    object-position: 0% 50%;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;

  width: 100%;

  height: 0;
  padding-top: 100%;

  z-index: 2;
  background: #fff;
  pointer-events: none;
  user-select: none;
  ${media.md.min} {
    padding-top: 0;
    width: 50%;
    height: 350px;
  }
`

const SlideImage = styled.div<{ active?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 29px;
  overflow: hidden;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const StyledSlidesNavigationWrapper = styled.div`
  padding-top: 100%;

  ${media.md.min} {
    width: fit-content;
    height: 350px;
    padding-top: 0;
    padding-left: calc(50%);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledNavigationButton = styled.p<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 107px;
  height: 47px;
  font-size: 24px;
  margin: 30px 0;
  font-weight: 500;
  user-select: none;
  cursor: pointer;
  position: relative;
  opacity: 0.2;
  line-height: 30px;

  ${media.lg.min} {
    margin: 20px 0;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}

  span {
    display: flex;
    position: absolute;
    font-size: 16px;
    left: 0;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.orange};
    width: 47px;
    height: 47px;
    border: 1px solid ${({ theme }) => theme.colors.orange};
    border-radius: 50%;
  }
`

const StyledAttractionDescription = styled.p<{ active?: boolean }>`
  display: none;
  line-height: 2.5;
  color: ${({ theme }) => theme.colors.orange};

  ${({ active }) =>
    active &&
    css`
      display: block;
    `}

  ${media.md.min} {
    display: none;
  }
`

const WhatYouCanDo = ({ attractions }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <StyledWrapper>
      <StyledText>Aurec Active</StyledText>
      <StyledTitle>
        Co możecie
        <br /> tu robić
      </StyledTitle>
      <StyledSliderWrapper>
        <ImageWrapper>
          {attractions.map(({ image }, index) => (
            <SlideImage active={index === activeIndex} key={index}>
              <StyledGatsbyImage image={image.src} alt={image.alt} />
            </SlideImage>
          ))}
        </ImageWrapper>
        <StyledSlidesNavigationWrapper>
          {attractions.map(({ title, description }, index) => {
            return (
              <>
                <StyledNavigationButton
                  onClick={() => setActiveIndex(index)}
                  active={index === activeIndex}
                >
                  <span>{index + 1}</span>
                  {title}
                </StyledNavigationButton>
                <StyledAttractionDescription active={index === activeIndex}>
                  {description}
                </StyledAttractionDescription>
              </>
            )
          })}
        </StyledSlidesNavigationWrapper>
      </StyledSliderWrapper>
    </StyledWrapper>
  )
}

export default WhatYouCanDo
