import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import media from 'styles/media'

type NeighbourhoodProps = {
  title: string
  description: string
  image: {
    src: IGatsbyImageData
    alt: string
  }
  sign: string
  title2: string
  description2: string
  image2: {
    src: IGatsbyImageData
    alt: string
  }
  sign2: string
}

const StyledWrapper = styled.section`
  padding: 0 20px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;

  ${media.lg.min} {
    margin-top: 150px;
    padding: 0 50px;
  }
`
const StyledPlaceWrapper = styled.div<{ reverse?: boolean }>`
  margin-top: 75px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  ${media.lg.min} {
    margin-top: 120px;
    justify-content: space-between;
    align-items: center;
  }
`
const StyledGatsbyImageWrapper = styled.div`
  img {
    border-radius: 21px;
    min-height: 270px;
  }

  ${media.lg.min} {
    flex-basis: 50%;

    img {
      min-height: 500px;
    }
  }
`
const StyledContentWrapper = styled.div`
  flex-basis: 100%;

  ${media.lg.min} {
    flex-basis: 40%;
  }
`
const StyledText = styled.p`
  color: ${({ theme }) => theme.colors.green};
  padding-top: 30px;

  ${media.lg.min} {
    padding-top: 0px;
  }
`
const StyledTitle = styled.p`
  margin-top: 30px;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4;
`
const StyledDescription = styled.p`
  margin-top: 30px;
  line-height: 2.5;
`

const Neighbourhood: React.FC<NeighbourhoodProps> = ({
  title,
  description,
  image,
  sign,
  title2,
  description2,
  image2,
  sign2,
}) => {
  return (
    <StyledWrapper>
      <StyledPlaceWrapper>
        <StyledGatsbyImageWrapper>
          <GatsbyImage image={image.src} alt={image.alt} />
        </StyledGatsbyImageWrapper>
        <StyledContentWrapper>
          <StyledText>{sign}</StyledText>
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>{description}</StyledDescription>
        </StyledContentWrapper>
      </StyledPlaceWrapper>
      <StyledPlaceWrapper reverse>
        <StyledGatsbyImageWrapper>
          <GatsbyImage image={image2.src} alt={image2.alt} />
        </StyledGatsbyImageWrapper>
        <StyledContentWrapper>
          <StyledText>{sign2}</StyledText>
          <StyledTitle>{title2}</StyledTitle>
          <StyledDescription>{description2}</StyledDescription>
        </StyledContentWrapper>
      </StyledPlaceWrapper>
    </StyledWrapper>
  )
}

export default Neighbourhood
